<template>
	<div class="top-header">
		<span :style="{ 'margin-left': topWidth + 'px' }" @click="setMenuStatus(menuStatus)" class="open-menu"
			:class="icon"></span>

		<div class="padding-r-sm info ">
			<div class="message">
				<el-badge :value="$store.state.user.info.message_station_nums" :hidden="$store.state.user.info.message_station_nums===0" class="item">
					<el-button icon="el-icon-message" type="primary" size="mini" @click="getEssageList()" circle />
				</el-badge>
				&nbsp;&nbsp;&nbsp;&nbsp;

				<div class="message-list" :class="messageListShow ? 'acitve' : ''" v-loading="messageLoading"
					v-if="messageListShow">
					<div class="message-title">站内消息</div>
					<ul class="message-box">

						<li class="messageList-item" v-for="item in messageList" :key="item.id"
							@click="getMessage(item)">
							<el-badge class="item" :is-dot="item.status === 0 ? true : false">
								<div class="item-title" >{{ item.title }}</div>
								<div class="item-time" >{{ item.created_at }}</div>
							</el-badge>
						</li>
					</ul>
					<div v-if="messageList.length > 0" class="messageList-more " style="border:0px"
						@click="$router.push('/deriveMessage')">查看更多
					</div>
				</div>
			</div>


      <div class="padding-r-sm">UID：{{ this.$store.state.user.info.id }}</div>
			<div class="padding-r-sm">昵称：{{ this.$store.state.user.info.name }}</div>

			<span class="padding-r-sm">
				<el-avatar size="small" :src="this.$store.state.user.info.avatar_url" />
			</span>

			<el-popconfirm title="退出登录?" icon="el-icon-warning" icon-color="#F56C6C" @confirm="confirm">
				<el-button type="text" slot="reference">退出登录</el-button>
			</el-popconfirm>


			<el-dialog :title="messageData.title" append-to-body :visible.sync="dialogVisible" width="30%"
				>
				<div style="padding:10px;" v-loading="dialogVisibleLoading">
					<span>{{ messageData.message}}</span>
					<div style="margin-top:40px;">
						<el-button @click="dialogVisible = false">关闭</el-button>
						<el-button type="primary" v-if="messageData.type == 1">
							<a :href="messageData.url">下载</a></el-button>
					</div>
				</div>

			</el-dialog>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { Message } from 'element-ui'

export default {
	name: 'top',
	data() {
		return {
			topWidth: 60,
			icon: 'el-icon-s-unfold',
			menuStatus: true,
			imageUrl: config.imageUrl,
			messageListShow: false,
			messageLoading: false,
			messageList: [],
			messageTotal: 0,
			dialogVisible: false,
			dialogVisibleLoading: false,
			messageData: {},
			message_station_nums:0,
			limit:10,
		}
	},
	methods: {
		...mapActions('user', ['getUserInfo']),
		...mapActions('message', ['userMessageStationList', 'userMessageStationDetail']),
		also() {
			this.page = ++this.page
			this.getEssageList(true)
		},
		async getMessage(row) {
			let id = row.id
			if(row.status == 0){
				this.$store.state.user.info.message_station_nums--
				row.status = 1
			}
			this.dialogVisible = true
			this.dialogVisibleLoading = true
			const { data } = await this.userMessageStationDetail(id)
			this.messageData = data
			this.dialogVisibleLoading = false
		},
		async getEssageList(msg) {
			if (this.messageListShow == true && !msg) return this.messageListShow = false
			this.messageListShow = true
			this.messageLoading = true
			let form = {
				limit:this.limit
			}
			const { data } = await this.userMessageStationList(form)
			this.messageList = data.list
			this.messageLoading = false
		},
		setMenuStatus(status) {
			if (this.menuStatus) {
				this.topWidth = 230
				this.icon = 'el-icon-s-fold'
			} else {
				this.topWidth = 60
				this.icon = 'el-icon-s-unfold'
			}
			this.menuStatus = !status
			this.$emit('setMenuStatus', this.menuStatus)
		},

		confirm() {
			this.$cookies.remove('userId')
			this.$cookies.remove('token')
			this.$cookies.set('isLogin', false)

			Message('请重新登录')

			this.$router.replace('/login')
		}
	},
	mounted() {
		// this.$store.dispatch('user/getUserInfoToState')

		// let socket = new WebSocket(
		// 	'ws://192.168.1.13:2346?token=73eb83b0-c423-3188-bb5a-68ae2ea2e73b'
		// )

		// socket.onopen = event => {
		// 	console.log(event)
		// }

		// socket.onmessage = msg => {
		// 	console.log(msg)
		// }
	},
	watch: {}
}
</script>

<style>
.info {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.top-header {
	height: 60px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.span-block {
	display: inline-block;
	padding: 0 10px;
	height: 60px;
	line-height: 60px;
}

.open-menu {
	font-size: 24px;
	padding: 20px;
	color: rgb(102, 102, 102);
	font-weight: normal;
	cursor: pointer;
}

.open-menu:hover {
	color: #409eff;
}


.message {
	display: flex;
	position: relative;
}

.message-list {
	z-index: 99;
	width: 400px;
	cursor: pointer;
	line-height: 22px;
	background: #fff;
	position: absolute;
	margin: auto;
	transform: translateY(-4px);
	opacity: 0;
	transition: transform 0.2s ease-in-out, opacity 0.3s linear;
	border-radius: 8px;
	box-shadow: 0 12px 32px rgba(0, 0, 0, .1), 0 2px 6px rgba(0, 0, 0, .08);
	right: -10px;
	top: 50px;
	overflow: auto;
}

/*定义滚动条样式（高宽及背景）*/
.message-box::-webkit-scrollbar-thumb {
	border-radius: 0.25em;
	background-color: #b9b9b9;
}

/*定义滚动条轨道（凹槽）样式*/
.message-box::-webkit-scrollbar {
	width: 0.5em;
	background-color: #d9d9d9;
}

/*定义滑块 样式*/

.acitve {
	opacity: 1;
	transform: translateY(0px);
	transition: transform 0.2s, opacity 0.3s linear;
}

.messageList-item {
	border-bottom: 1px solid #eee;
	padding: 6px 10px;
}
.message-title{
	padding: 5px 10px;
	border-bottom: solid 1px #eee;
	background: #f3f3f3;
}
.message-box{
	height: 220px;
	overflow: auto;
}
.item{
	display: flex;
	justify-content: space-between;
}
.item-title{
	font-size: 10px;
	width:calc(100% - 120px);
	overflow: hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}
.item-time{
	width: 120px;
	font-size: 8px;
	color:#333;
}
	.messageList-more{
		padding: 6px 10px;
		text-align: center;
		font-size:12px;
		color: #999;
	}
</style>
